import { __decorate } from "tslib";
import { Component, Prop, Vue, Watch, } from 'vue-property-decorator';
import { Upload, Button, Icon, Progress, } from '@h3/antd-vue';
import { CommonServiceExtensionService } from '@/sevices';
let commonServiceExtensionImport = class commonServiceExtensionImport extends Vue {
    constructor() {
        super(...arguments);
        this.visible = false;
        this.fileList = {};
        this.canImport = false;
        this.importStatus = '';
        // 导入进度
        this.percent = 0;
        // 是否导入完成
        this.isImportEnd = false;
        this.downloadDisplay = false;
        this.Progress = 1;
        this.showFile = false;
    }
    formatName(name) {
        if (name.length > 18) {
            const nameStart = name.substr(0, 7);
            const nameEnd = name.substr(name.length - 11, 7);
            return `${nameStart}...${nameEnd}.jar`;
        }
        return name;
    }
    progressInfo(e) {
        if (e.lengthComputable) {
            const progress = Math.floor((e.loaded / e.total) * 100);
            this.Progress = progress;
        }
    }
    get appCode() {
        return this.$route.params.appcode;
    }
    handleChange(info) {
        const isLimitSize = info.file.size / 1024 < 20480;
        if (!isLimitSize) {
            this.reset();
            this.$message.error('jar包大小不能超过20MB');
            return false;
        }
        this.showFile = Boolean(info.file);
        this.fileList = info.file;
        if (this.showFile) {
            this.canImport = true;
        }
    }
    /**
     * 判断上传的文件是否zip文件
     */
    beforeUpload() {
        return false;
    }
    remove(file) {
        this.fileList = {};
        this.showFile = false;
        this.canImport = false;
        this.$emit('change', false);
        this.$emit('remove', file.response);
    }
    getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
    async confirm() {
        this.importStatus = 'importing';
        this.getBase64(this.fileList)
            .then(() => {
            const extensionJarId = this !== undefined && this.detail !== undefined ? this.detail.id : '';
            CommonServiceExtensionService.uploadExtensionJar(extensionJarId, this.fileList, (progress) => {
                this.percent = Math.round((progress.loaded / progress.total) * 100);
            })
                .then(res => {
                if (res.success) {
                    this.importStatus = 'importEnd';
                    this.isImportEnd = true;
                }
                else {
                    this.reset();
                }
            });
        })
            .catch(() => {
            this.reset();
            Vue.prototype.$errorcatcher.showMessagePopup({
                title: '文件不存在',
                message: ['File does not exist!', 'jar包不存在'],
                correctGuide: '请检查本地文件是否已经修改或删除',
                displayFooter: false,
            });
        });
    }
    async cancel() {
        this.$emit('input', false);
        this.reset();
    }
    finish() {
        this.$emit('finish');
        this.$emit('input', false);
        this.reset();
    }
    reset() {
        this.importStatus = '';
        this.isImportEnd = false;
        this.percent = 0;
        this.showFile = false;
        this.canImport = false;
        this.fileList = {};
        this.$router.go(0);
    }
    onChange(v) {
        this.visible = v;
    }
};
__decorate([
    Prop({ default: '上传服务' })
], commonServiceExtensionImport.prototype, "title", void 0);
__decorate([
    Prop({ default: '服务' })
], commonServiceExtensionImport.prototype, "text", void 0);
__decorate([
    Prop()
], commonServiceExtensionImport.prototype, "detail", void 0);
__decorate([
    Prop({ default: () => [] })
], commonServiceExtensionImport.prototype, "initTableData", void 0);
__decorate([
    Prop()
], commonServiceExtensionImport.prototype, "value", void 0);
__decorate([
    Watch('value', { immediate: true })
], commonServiceExtensionImport.prototype, "onChange", null);
commonServiceExtensionImport = __decorate([
    Component({
        name: 'commonServiceExtensionImport',
        components: {
            AUploadDragger: Upload.Dragger,
            AButton: Button,
            AIcon: Icon,
            AProgress: Progress,
        },
    })
], commonServiceExtensionImport);
export default commonServiceExtensionImport;
