import { __decorate } from "tslib";
import { Component, Vue, Ref } from 'vue-property-decorator';
import { CommonServiceExtensionService } from '@/sevices';
import commonServiceExtensionImport from './common-service-extension-import.vue';
import { CTable } from '@cloudpivot-hermes/common-components';
let commonServiceExtension = class commonServiceExtension extends Vue {
    constructor() {
        super(...arguments);
        this.moduleName = 'commonServiceExtension';
        this.showCommonServiceExtensionImport = false;
        this.serviceTitle = '上传服务';
        this.tableList = [];
        this.loading = true;
        this.detail = {};
        this.tableColumns = [
            {
                width: 60,
                dataIndex: 'index',
                key: 'index',
                align: 'center',
                slots: { title: 'indexTitle', name: '序号' },
                scopedSlots: { customRender: 'index' },
            },
            {
                dataIndex: 'fileName',
                key: 'fileName',
                ellipsis: true,
                slots: { title: 'fileNameTitle', name: '扩展Jar包名称' },
            },
            {
                dataIndex: 'refId',
                key: 'refId',
                ellipsis: true,
                slots: { title: 'refIdTitle', name: '文件Id' },
            },
            {
                dataIndex: 'createdTime',
                key: 'createdTime',
                ellipsis: true,
                slots: { title: 'createdTimeTitle', name: '创建时间' },
            },
            {
                dataIndex: 'action',
                key: 'action',
                width: 100,
                slots: { title: 'actionTitle', name: '操作' },
                scopedSlots: { customRender: 'action' },
            },
        ];
    }
    created() {
        this.getList();
    }
    async getList() {
        this.loading = true;
        const res = await CommonServiceExtensionService.listExtensionJars();
        this.loading = false;
        if (res.success) {
            this.tableList = res.data;
            return res;
        }
    }
    delHandler(record) {
        const okOk = () => {
            const param = record.id;
            CommonServiceExtensionService.delExtensionJar(param).then(() => {
                this.$message.success('删除成功！');
                this.reload();
            });
        };
        this.$confirm({
            title: '确定要卸载这个扩展服务吗？',
            content: '卸载后，该扩展的服务将不可用，确认卸载吗？',
            okText: '确定',
            cancelText: '取消',
            onOk: okOk,
        });
    }
    reload() {
        this.ctable.getTableList();
    }
    uploadExtensionJar(record) {
        this.showCommonServiceExtensionImport = true;
        this.detail = record;
    }
    initService() {
        this.$emit('initService');
    }
};
__decorate([
    Ref()
], commonServiceExtension.prototype, "ctable", void 0);
commonServiceExtension = __decorate([
    Component({
        name: 'commonServiceExtension',
        components: {
            CTable,
            commonServiceExtensionImport,
        },
    })
], commonServiceExtension);
export default commonServiceExtension;
